const theme = {
  colors: {
    black: '#000000',
    white: '#ffffff',
    blue: '#0045FF',
    darkBlue: '#0000FB',
    altBlue: '#2075ff',
    lightBlue: '#cae5fb',
    blueOverlay: '#0025FE',
    greyOverlay: '#EEF0F2',
    darkOverlay: '#222426',
    pink: '#ff80a9',
    green: '#a0ffe6',
    red: '#ff3c5e',
    wash: '#f0f4ff',
    lightGrey: '#e1e4e6',
    mediumGrey: '#aab3be',
    grey: 'rgba(255, 255, 255, 0.5)',
    darkGrey: '#1f1f1f',
    strokeGrey: '#2b2d2f',
    highlight: 'rgba(33, 150, 243, 0.4)',
    controlGrey: 'rgba(255, 255, 255, 0.4)',
  },

  fonts: {
    base: 'Dia, -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif',
  },

  fontSizes: {
    xs: '12px',
    sm: '14px',
    base: '16px',
    md: '18px',
    lg: '20px',
    xl: '30px',
  },

  spacing: {
    xs: '5px',
    sm: '10px',
    base: '20px',
    md: '30px',
    lg: '50px',
    xl: '100px',
  },

  easing: 'cubic-bezier(0.7, 0, 0.3, 1)',
  transition: 'all 0.25s ease-in-out',
};

export default theme;
