import { trimPath, parseParams, prettifySlug } from '@utils';

export default location => {
  if (!location) {
    return {};
  }

  const { pathname, search } = location;
  const department = prettifySlug(trimPath(pathname));
  const params = location && search ? parseParams(search) : null;
  const position = prettifySlug(params && params.position ? params.position : '');

  return {
    department,
    position,
  };
};
