import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { theme, mixins, media } from '@styles';
const { colors, spacing } = theme;

const PreambleContainer = styled.div`
  ${mixins.flexCenter};
  flex-direction: column;

  h1,
  h3,
  p {
    text-align: center;
  }
  h1 {
    margin: ${spacing.md} 0;
    font-size: 72px;
    ${media.phone`
      font-size: 40px;
    `};
  }
  h3 {
    color: ${colors.white};
  }
  p {
    margin-bottom: 0;
    max-width: 500px;
    color: ${colors.grey};
  }
  strong {
    color: ${colors.altBlue};
  }
  ul {
    list-style-type: none;
    li {
      margin-bottom: 10px;
      &:before {
        content: '—';
        font-size: 20px;
        margin: 0 0.7em 0 -1em;
        color: ${colors.altBlue};
        -webkit-font-smoothing: none;
      }
    }
  }
`;
const Checklist = styled.div`
  margin: ${spacing.lg} auto;
  color: ${colors.grey};
`;

const Preamble = ({ position, jobLink, start }) => (
  <PreambleContainer>
    <h1>Apply to Upstatement</h1>
    <p>
      Thanks for your interest in our{' '}
      <a href={jobLink} target="_blank" rel="noopener noreferrer">
        <strong>{position}</strong>
      </a>{' '}
      position! Most people take 5 minutes to fill out this form.
    </p>
    <Checklist>
      <h3>A few things you&apos;ll need:</h3>
      <ul>
        <li>PDF of your resume</li>
        <li>Link to your portfolio</li>
        <li>Cover letter (optional)</li>
      </ul>
    </Checklist>
    <button onClick={() => start()}>Start Application</button>
  </PreambleContainer>
);

Preamble.propTypes = {
  position: PropTypes.string.isRequired,
  jobLink: PropTypes.string.isRequired,
  start: PropTypes.func.isRequired,
};

export default Preamble;
