import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { media, theme } from '@styles';
const { colors, spacing, fontSizes } = theme;

const ListContainer = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: ${spacing.md};
  list-style: none;
  padding: 0;
  margin: 5vh ${spacing.lg} 20vh;
  ${media.tablet`
    margin: 5vh ${spacing.md} 200px;
  `};
  ${media.phone`
    margin: 5vh ${spacing.base} 200px;
  `};
  li {
    a {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 40px;
      width: 100%;
      height: 100%;
      min-height: 200px;
      border-top: 1px solid ${colors.controlGrey};
      &:hover,
      &:focus {
        background-color: ${colors.blue};
        border-color: ${colors.blue};
      }
      h2 {
        font-size: ${fontSizes.xl};
      }
      p {
        color: ${colors.white};
        text-transform: uppercase;
        font-size: ${fontSizes.xs};
        letter-spacing: 1px;
        font-weight: 700;
      }
    }
  }
`;

const Layout = ({ list }) => (
  <ListContainer>
    {list.map((item, i) => (
      <li key={i}>
        <a href={item.path}>
          <h2>{item.name}</h2>
          <p>See Application</p>
        </a>
      </li>
    ))}
  </ListContainer>
);

Layout.propTypes = {
  list: PropTypes.array.isRequired,
};

export default Layout;
