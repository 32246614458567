import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { theme, mixins, animations, media } from '@styles';
const { colors, spacing, fontSizes } = theme;
const { arrowFlash } = animations;

const ProgressBar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 7px;
  background-color: ${colors.altBlue};
  width: ${props => props.progress}%;
  transition: ${theme.transition};
  z-index: 2;
`;
const StepCounter = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  margin: ${spacing.md};
  font-size: 30px;
  line-height: 1;
  font-weight: 700;
  color: ${colors.controlGrey};
  z-index: 2;
  ${media.phone`
    font-size: ${fontSizes.lg};
    margin: ${spacing.md} ${spacing.base};
  `};
`;
const NavControls = styled.div`
  ${mixins.flexCenter};
  flex-direction: column;
  position: fixed;
  bottom: 0;
  right: 0;
  margin: ${spacing.md};
  font-size: 14px;
  text-align: center;
  color: ${colors.controlGrey};
  max-width: 70px;
  z-index: 2;
  ${media.phone`
    margin: ${spacing.md} ${spacing.base};
  `};
  p {
    margin: 0;
    line-height: 1.25;
    ${media.phone`
      display: none;
    `};
  }
`;
const ControlButtons = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  ${media.phone`
    margin-bottom: 0;
  `};
`;
const ControlButton = styled.button`
  border-color: ${colors.controlGrey};
  color: ${colors.controlGrey};
  font-weight: 700;
  font-size: 24px;
  padding: 5px 20px;
  transition: all 0.15s;
  ${props => (props.arrowClicked && props.activeField ? arrowFlash : ``)};

  &:last-of-type {
    border-top: 0;
  }
`;
const Controls = ({
  preambleActive,
  activeField,
  animDirection,
  arrowClicked,
  content,
  readyForReview,
  prev,
  next,
}) => (
  <>
    <ProgressBar progress={(activeField / content.length) * 100} />

    <StepCounter>
      {activeField} / {content.length}
    </StepCounter>

    {!preambleActive && (
      <NavControls>
        <ControlButtons>
          <ControlButton
            activeField={animDirection === 'prev'}
            arrowClicked={arrowClicked}
            onClick={prev}>
            &uarr;
          </ControlButton>
          <ControlButton
            activeField={animDirection === 'next'}
            arrowClicked={arrowClicked}
            onClick={next}
            disabled={readyForReview}>
            &darr;
          </ControlButton>
        </ControlButtons>
        <p>or use your keyboard</p>
      </NavControls>
    )}
  </>
);

Controls.propTypes = {
  preambleActive: PropTypes.bool.isRequired,
  activeField: PropTypes.number.isRequired,
  animDirection: PropTypes.string.isRequired,
  arrowClicked: PropTypes.bool.isRequired,
  content: PropTypes.array.isRequired,
  readyForReview: PropTypes.bool.isRequired,
  prev: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
};

export default Controls;
