import styled from 'styled-components';
import { theme, media } from '@styles';
const { colors, spacing } = theme;

const Checklist = styled.ul`
  ${media.phone`
    padding-left: ${spacing.base};
  `};
  li {
    position: relative;
    list-style: none;
    display: block;

    input {
      position: absolute;
      opacity: 0;
      width: auto;
      cursor: pointer;

      &:hover,
      &:focus {
        & + label:before {
          background: ${colors.altBlue};
        }
      }
      &:checked + label {
        &:before {
          background: ${colors.altBlue};
        }
        &:after {
          content: '';
          position: absolute;
          top: 22px;
          left: 7px;
          background: ${colors.white};
          width: 2px;
          height: 2px;
          box-shadow: 2px 0 0 ${colors.white}, 4px 0 0 ${colors.white}, 4px -2px 0 ${colors.white},
            4px -4px 0 ${colors.white}, 4px -6px 0 ${colors.white}, 4px -8px 0 ${colors.white};
          transform: rotate(45deg);
        }
      }
      & + label {
        display: inline-flex;
        position: relative;
        padding: 10px 0;
        font-weight: 400;
        z-index: 10;
        cursor: pointer;

        &:before {
          content: '';
          display: inline-block;
          margin-right: ${spacing.base};
          width: 25px;
          min-width: 25px;
          height: 25px;
          background: ${colors.strokeGrey};
          vertical-align: middle;
          transition: ${theme.transition};
          flex-grow: 0;
        }

        span {
          line-height: 1.3;
        }
      }
    }
  }
`;

export default Checklist;
