import { createGlobalStyle } from 'styled-components';
import {
  DiaRegularEOT,
  DiaRegularWOFF,
  DiaRegularTTF,
  DiaBoldEOT,
  DiaBoldWOFF,
  DiaBoldTTF,
} from './fonts';
import theme from './theme';
import mixins from './mixins';
const { colors, fonts, fontSizes } = theme;

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Dia';
    src: url(${DiaRegularEOT});
    src: url(${DiaRegularEOT}) format('embedded-opentype'),
        url(${DiaRegularWOFF}) format('woff'),
        url(${DiaRegularTTF}) format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Dia';
    src: url(${DiaBoldEOT});
    src: url(${DiaBoldEOT}) format('embedded-opentype'),
        url(${DiaBoldWOFF}) format('woff'),
        url(${DiaBoldTTF}) format('truetype');
    font-weight: 700;
    font-style: normal;
  }

  html {
    box-sizing: border-box;
    width: 100%;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  html,
  body {
    width: 100%;
    height: 100%;
    min-height: 100vh;
  }

  body {
    margin: 0;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    background-color: ${colors.black};
    color: ${colors.white};
    font-family: ${fonts.base};
    font-size: ${fontSizes.lg};
    line-height: 1.1em;
    letter-spacing: -.03em;

    & > div {
      height: 100%;
      & > div {
        height: 100%;
      }
    }
  }

  &::selection {
    background-color: ${colors.highlight};
  }

  a {
    ${mixins.link};
  }

  img {
    width: 100%;
    max-width: 100%;
    vertical-align: middle;
  }

  svg {
    width: 100%;
    height: 100%;
    fill: currentColor;
    vertical-align: middle;
  }

  button {
    ${mixins.button};
  }

  input, textarea {
    font-size: ${fontSizes.lg};
    font-family: ${fonts.base};
    letter-spacing: -.03em;
    margin: 0;
    padding: 20px 0 5px;
    width: 100%;
    border: none;
    border-bottom: 2px solid ${colors.strokeGrey};
    background-color: transparent;
    color: ${colors.white};
    transition: ${theme.transition};

    &:focus {
      border-color: rgba(255,255,255,0.4);
      outline: 0;
      &::placeholder {
        color: transparent;
      }
    }
    &::placeholder {
      color: ${colors.controlGrey};
      letter-spacing: -.03em;
    }
  }

  input[type='file'] {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
    transition: ${theme.transition};
  }

  p {
    margin-top: 0;
    letter-spacing: 0px;
    line-height: 1.5;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
    line-height: 1.25;
    margin-top: 0;
    margin-bottom: 20px;
  }
`;

export default GlobalStyle;
