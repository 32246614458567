import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import favicon from '../images/favicons/favicon.ico';
import favicon16 from '../images/favicons/favicon-16x16.png';
import favicon32 from '../images/favicons/favicon-32x32.png';
import appleTouchIcon from '../images/favicons/apple-touch-icon.png';
import safariIcon from '../images/favicons/safari-pinned-tab.svg';
import androidIcon192 from '../images/favicons/android-chrome-192x192.png';

const Head = ({ siteMetadata }) => (
  <Helmet>
    <title>{siteMetadata.title}</title>

    <html lang="en" />

    <link rel="shortcut icon" href={favicon} />

    <meta name="description" content={siteMetadata.description} />
    <meta property="og:title" content={siteMetadata.title} />
    <meta property="og:description" content={siteMetadata.description} />
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://www.upstatement.com/" />
    <meta property="og:site_name" content={siteMetadata.title} />
    <meta property="og:image" content={siteMetadata.ogImage} />
    <meta property="og:image:width" content="1200" />
    <meta property="og:image:height" content="630" />
    <meta property="og:image:type" content="image/jpb" />

    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:url" content="https://www.upstatement.com/" />
    <meta name="twitter:site" content={siteMetadata.twitterHandle} />
    <meta name="twitter:creator" content={siteMetadata.twitterHandle} />
    <meta name="twitter:domain" content="https://www.upstatement.com/" />
    <meta name="twitter:title" content={siteMetadata.title} />
    <meta name="twitter:description" content={siteMetadata.description} />
    <meta name="twitter:image" content={siteMetadata.ogImage} />
    <meta name="twitter:image:alt" content={siteMetadata.title} />

    <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
    <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
    <link rel="apple-touch-icon" sizes="180x180" href={appleTouchIcon} />
    <link rel="icon" type="image/png" sizes="192x192" href={androidIcon192} />
    <link rel="mask-icon" href={safariIcon} color="#000000" />
    <meta name="msapplication-TileColor" content="#000000" />
  </Helmet>
);

export default Head;

Head.propTypes = {
  siteMetadata: PropTypes.object.isRequired,
};
