import React from 'react';
import PropTypes from 'prop-types';

import Logo from '@components/icons/logo';

import styled from 'styled-components';
import { theme, media } from '@styles';
const { colors, spacing, fontSizes } = theme;

const HeaderContainer = styled.header`
  padding: ${spacing.md};
  ${media.tablet`
    padding: ${spacing.md} ${spacing.base};
  `};
  a {
    display: block;
    color: ${colors.white};
  }
  svg {
    max-width: 120px;
    height: 25px;
    fill: ${colors.white};
  }
`;
const Position = styled.h1`
  font-size: ${fontSizes.xl};
  line-height: 1;
  color: ${colors.altBlue};
  margin: 5px 0 0;
  ${media.phone`
    font-size: 24px;
  `};
`;

const Header = ({ position, jobLink }) => (
  <HeaderContainer>
    <a href="https://www.upstatement.com/" target="_blank" rel="noopener noreferrer">
      <Logo />
    </a>
    <a href={jobLink} target="_blank" rel="noopener noreferrer">
      <Position>{position}</Position>
    </a>
  </HeaderContainer>
);

Header.propTypes = {
  position: PropTypes.string,
  jobLink: PropTypes.string.isRequired,
};

export default Header;
