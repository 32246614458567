import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby-link';

import { parseLocation } from '@utils';
import { Preamble, Controls, Field } from '@components';

import styled, { css } from 'styled-components';
import { theme, mixins, media, animations } from '@styles';
const { colors, spacing, fontSizes } = theme;
const { slideUpNext, rotate } = animations;

const ApplyContainer = styled.div`
  position: relative;
  min-height: 100%;
`;
const FormContainer = styled.div`
  height: 100%;
  margin: ${spacing.xl} ${spacing.lg};
  visibility: visible;
  ${props =>
    props.preambleActive &&
    css`
      margin: 0;
      visibility: hidden;
    `};
  ${media.tablet`
    margin: ${spacing.lg};
  `};
  ${media.phone`
    margin: 0;
  `};

  form {
    position: relative;
    font-weight: 700;
    h2 {
      font-size: 60px;
      margin-bottom: 100px;
      ${props =>
    props.readyForReview &&
        css`
          ${slideUpNext};
        `};
      ${media.tablet`
        font-size: 40px;
      `};
      ${media.phone`
        font-size: ${fontSizes.xl};
        margin-bottom: 50px;
      `};
    }
    input {
      text-overflow: ellipsis;
      font-weight: 700;
      font-size: ${props => (props.readyForReview ? `24px` : `60px`)};
      ${media.phone`
        font-size: 24px;
      `};
    }
  }
`;
const SubmitContainer = styled.div`
  ${mixins.flexCenter};
  width: 100%;
  margin: ${spacing.xl} 0 0 0;
  ${media.tablet`
    margin: ${spacing.xl} 0;
  `};
  button {
    ${props =>
    props.isLoading &&
      css`
        width: 50px;
        height: 50px;
        padding: 0;
        border-radius: 100%;
        border-width: 5px;
        border-color: ${colors.controlGrey};
        border-left-color: ${colors.altBlue};
        ${rotate};
        span {
          font-size: 0;
        }
        &:hover,
        &:focus {
          background-color: transparent;
        }
      `};
  }
  .thumbs {
    font-size: ${fontSizes.xl};
  }
`;

function encode(data) {
  const formData = new FormData();
  for (const key of Object.keys(data)) {
    formData.append(key, data[key]);
  }
  return formData;
}

class Apply extends Component {
  static propTypes = {
    content: PropTypes.array.isRequired,
    location: PropTypes.object.isRequired,
    jobLink: PropTypes.string.isRequired,
  };

  state = {
    preambleActive: true,
    activeField: 0,
    animDirection: 'next',
    readyForReview: false,
    arrowClicked: false,
    isValid: true,
    fileName: '',
    form: {},
    isLoading: false,
    submitSuccess: false,
    ...parseLocation(this.props.location),
  };

  componentDidMount() {
    document.addEventListener('keydown', e => this.handleKeyboard(e));
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyboard);
  }

  handleKeyboard = e => {
    if (this.state.readyForReview) {
      return;
    }

    if (e.key === 'ArrowUp') {
      e.preventDefault();
      this.prev();
    }

    if (e.key === 'ArrowDown' || e.key === 'Enter') {
      e.preventDefault();
      this.next();
    }
  };

  prev = () => {
    const { activeField, readyForReview } = this.state;

    if (readyForReview) {
      document.body.classList.remove('scroll');
    }

    if (activeField < 1) {
      return;
    }

    if (activeField === 1) {
      this.setState({ preambleActive: true });
    }

    this.setState({
      activeField: activeField - 1,
      animDirection: 'prev',
      readyForReview: false,
      arrowClicked: true,
      isValid: true,
    });

    setTimeout(() => this.setState({ arrowClicked: false }), 300);
  };

  next = () => {
    const { content } = this.props;
    const { preambleActive, activeField } = this.state;

    if (activeField === content.length - 1) {
      this.setState({ readyForReview: true });
    }

    if (preambleActive) {
      this.setState({
        preambleActive: false,
        activeField: activeField + 1,
        animDirection: 'next',
      });

      return;
    }

    this.setState({ isValid: this.isFieldValid() }, () => {
      const { isValid } = this.state;

      if (activeField >= content.length || !isValid) {
        return;
      }

      this.setState({
        activeField: activeField + 1,
        animDirection: 'next',
        arrowClicked: true,
      });

      setTimeout(() => this.setState({ arrowClicked: false }), 300);
    });
  };

  isFieldValid = () => {
    const { activeField } = this.state;
    const currentField = document.getElementById(`field${activeField}`);
    const input = currentField.querySelector('input') || currentField.querySelector('textarea');
    const isValid = input && input.checkValidity();
    return isValid;
  };

  handleChange = e => {
    const { form } = this.state;

    this.setState({
      form: { ...form, [e.target.name]: e.target.value },
      isValid: true,
    });
  };

  handleFileUpload = e => {
    const { form } = this.state;
    const file = e.target.files[0];

    // Set file size limit to 10MB (1,000,000 bytes === 1MB)
    const limit = 10000000;
    if (e.target.files[0].size > limit) {
      alert('Your file should be no larger than 10MB');
      return;
    }

    this.setState({
      fileName: file.name,
      form: {
        ...form,
        [e.target.name]: file,
      },
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { location } = this.props;
    const form = e.target;

    const formBody = {
      'form-name': form.getAttribute('name'),
      position: this.state.position,
      ...this.state.form,
    };

    this.setState({ isLoading: true });

    fetch(`${location.href}`, {
      method: 'POST',
      body: encode(formBody),
    })
      .then(() => {
        this.setState({
          isLoading: false,
          submitSuccess: true,
        });

        setTimeout(() => navigate(form.getAttribute('action')), 1000); // go to success page
      })
      .catch(error => alert(error));
  };

  render() {
    const { content, jobLink } = this.props;
    const {
      preambleActive,
      fileName,
      activeField,
      animDirection,
      readyForReview,
      arrowClicked,
      isValid,
      isLoading,
      submitSuccess,
      department,
      position,
    } = this.state;

    return (
      <ApplyContainer>
        <Controls
          preambleActive={preambleActive}
          activeField={activeField}
          animDirection={animDirection}
          arrowClicked={arrowClicked}
          content={content}
          readyForReview={readyForReview}
          prev={this.prev}
          next={this.next}
        />

        {preambleActive && <Preamble position={position} jobLink={jobLink} start={this.next} />}

        <FormContainer readyForReview={readyForReview} preambleActive={preambleActive}>
          <form
            name={department}
            method="POST"
            action="/success/"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={this.handleSubmit}>
            {readyForReview && <h2>Review your application</h2>}

            {/* The form-name field is required to support form submissions without JS */}
            <input type="hidden" name="form-name" value={department} />

            <input type="hidden" name="position" value={position} />

            <div style={{ visibility: 'hidden', display: 'none' }}>
              <label htmlFor="bot-field">Don't fill this out if you're human</label>
              <input type="hidden" name="bot-field" onChange={this.handleChange} />
            </div>

            <Field
              content={content}
              activeField={activeField}
              animDirection={animDirection}
              preambleActive={preambleActive}
              readyForReview={readyForReview}
              fileName={fileName}
              isValid={isValid}
              handleFileUpload={this.handleFileUpload}
              handleChange={this.handleChange}
            />

            {readyForReview && (
              <SubmitContainer isLoading={isLoading} submitSuccess={submitSuccess}>
                {submitSuccess ? (
                  <span role="img" aria-label="thumbs up emoji" className="thumbs">
                    👍
                  </span>
                ) : (
                  <button type="submit" disabled={isLoading}>
                    <span>Submit!</span>
                  </button>
                )}
              </SubmitContainer>
            )}
          </form>
        </FormContainer>
      </ApplyContainer>
    );
  }
}

export default Apply;
