import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { parseLocation, getJobPosition } from '@utils';

import { Head, Header } from '@components';

import styled from 'styled-components';
import { GlobalStyle, theme, media } from '@styles';
const { spacing } = theme;

const SiteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100%;
`;
const Content = styled.main`
  position: relative;
  width: 100%;
  max-width: 1280px;
  flex-grow: 1;
  margin: 0 auto;
  padding: ${spacing.md};

  ${media.tablet`
    padding: ${spacing.md} ${spacing.base};
  `};
`;

const Layout = ({ children, location, positions }) => {
  const shouldRedirect = process.env.GATSBY_REDIRECTS === 'true';
  const { position } = parseLocation(location);
  const jobPosition = getJobPosition(positions, position);
  const jobLink = jobPosition ? jobPosition.description : 'https://www.upstatement.com/jobs';

  if (typeof window !== `undefined` && shouldRedirect) {
    const whitelist = location && location.pathname === '/success/';

    // Redirect to jobs page if there is no position query param and it's not the success page
    if (!jobPosition && !whitelist) {
      window.location.replace('https://www.upstatement.com/jobs');
    }
  }

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
              description
              ogImage
              twitterHandle
            }
          }
        }
      `}
      render={({ site }) => (
        <SiteWrapper>
          <Head siteMetadata={site.siteMetadata} />
          <Helmet>
            <title>{position ? `${position} | Upstatement` : site.siteMetadata.title}</title>
          </Helmet>

          <GlobalStyle />

          <Header siteTitle={site.siteMetadata.title} position={position} jobLink={jobLink} />

          <Content>{children}</Content>
        </SiteWrapper>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object,
  positions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
};

export default Layout;
