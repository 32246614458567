import { keyframes, css } from 'styled-components';
import theme from './theme';
const { colors } = theme;

const kfs = {
  animHideNext: keyframes`
    to {
      opacity: 0;
      transform: translate3d(0, -500px, 0);
    }
  `,
  animShowNext: keyframes`
    from {
      opacity: 0;
      transform: translate3d(0, 500px, 0);
    }
  `,
  animHidePrev: keyframes`
    to {
      opacity: 0;
      transform: translate3d(0, 500px, 0);
    }
  `,
  animShowPrev: keyframes`
    from {
      opacity: 0;
      transform: translate3d(0, -500px, 0);
    }
  `,
  animArrowFlash: keyframes`
    from {
      background-color: transparent;
    }
  	to {
      background-color: ${colors.altBlue};
      border-color: ${colors.altBlue};
      color: ${colors.white};
    }
  `,
  animFadeIn: keyframes`
    0% {
      opacity: 0;
    }
    90% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  `,
  animSlideUp: keyframes`
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  `,
  animRotate: keyframes`
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  `,
};

const animations = {
  // Slide up current field when navigating to next question
  slideUpCurrent: css`
    animation: ${kfs.animHideNext} 0.5s ${theme.easing} forwards;
  `,
  // Slide up next field when navigating to next question
  slideUpNext: css`
    animation: ${kfs.animShowNext} 0.5s ${theme.easing} both 0.15s;
  `,
  // Slide down current field when navigating to previous question
  slideDownCurrent: css`
    animation: ${kfs.animHidePrev} 0.5s ${theme.easing} forwards;
  `,
  // Slide down previous field when navigating to previous question
  slideDownPrev: css`
    animation: ${kfs.animShowPrev} 0.5s ${theme.easing} both 0.15s;
  `,
  arrowFlash: css`
    animation: ${kfs.animArrowFlash} 0.3s;
  `,
  tempHide: css`
    animation: ${kfs.animFadeIn} 0.5s ${theme.easing};
  `,
  slideUp: css`
    animation: ${kfs.animSlideUp} 0.5s ${theme.easing};
  `,
  rotate: css`
    animation: ${kfs.animRotate} 2s 0.25s linear infinite;
  `,
};

export default animations;
