import styled from 'styled-components';
import { theme, media } from '@styles';
const { spacing } = theme;

const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100%;
  text-align: center;
  margin: 10vh ${spacing.lg} 0;
  ${media.tablet`
    margin: 5vh ${spacing.md};
  `};
  ${media.phone`
    margin: 5vh ${spacing.base};
  `};
  h1 {
    margin-top: 0;
  }
  img {
    margin-top: 50px;
  }
`;

export default Main;
