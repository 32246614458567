import React from 'react';
import PropTypes from 'prop-types';

import styled, { css } from 'styled-components';
import { theme, mixins, media, animations, Checklist } from '@styles';
const { colors, fontSizes } = theme;
const {
  slideUpCurrent,
  slideUpNext,
  slideDownCurrent,
  slideDownPrev,
  tempHide,
  slideUp,
} = animations;

const FieldLabel = styled.div`
  color: ${colors.altBlue};
  line-height: 1.2;
`;
const FieldContent = styled.div`
  position: relative;
`;
const FieldError = styled.p`
  color: ${colors.red};
  margin-top: 30px;
  ${slideUp};
  ${media.phone`
    font-size: ${fontSizes.base};
  `};
`;
const FieldWrapper = styled.div`
  width: 100%;
  position: ${props => (props.readyForReview ? `relative` : `absolute`)};
  visibility: ${props => (props.isActive || props.readyForReview ? 'visible' : 'hidden')};
  z-index: ${props => (props.isActive || props.readyForReview ? 1 : -1)};
  height: ${props => (props.isActive || props.readyForReview ? 'auto' : 0)};
  margin-bottom: ${props => (props.readyForReview ? '70px' : 0)};
  ${props =>
    props.preambleActive &&
    css`
      opacity: 0;
    `};

  ${FieldLabel},
  ${FieldContent} {
    transition: all 0.5s ${theme.easing};
    ${props =>
    props.animDirection === 'next'
      ? props.isActive
        ? slideUpNext
        : slideUpCurrent
      : props.isActive
        ? slideDownPrev
        : slideDownCurrent};
  }
  ${FieldLabel} {
    font-size: ${props => (props.readyForReview ? fontSizes.xl : '36px')};
    margin-bottom: ${props => (props.readyForReview ? '0' : '30px')};
    animation-delay: ${props =>
    props.animDirection === 'next'
      ? props.isActive
        ? '0.1s'
        : '0s'
      : props.isActive
        ? '0.25s'
        : '0.1s'};
    ${media.phone`
      font-size: 24px;
    `};
  }
  ${FieldContent} {
    animation-delay: ${props =>
    props.animDirection === 'next'
      ? props.isActive
        ? '0.25s'
        : '0.1s'
      : props.isActive
        ? '0.1s'
        : '0s'};
  }

  ${props =>
    props.readyForReview
      ? css`
          ${slideUpNext};
          animation-delay: 0.25s;
          ${FieldLabel},
          ${FieldContent} {
            transform: none;
            animation: none;
          }
        `
      : css`
          ${tempHide};
        `};
`;
const LabelButton = styled.label`
  ${mixins.button};
  margin-top: 40px;
`;

const Field = ({
  content,
  activeField,
  animDirection,
  preambleActive,
  readyForReview,
  fileName,
  isValid,
  handleFileUpload,
  handleChange,
}) => (
  <>
    {content &&
      content.map((field, i) => (
        <FieldWrapper
          key={i}
          id={`field${i + 1}`}
          isActive={activeField === i + 1}
          animDirection={animDirection}
          preambleActive={preambleActive}
          readyForReview={readyForReview}>
          <FieldLabel>{field.label}</FieldLabel>

          <FieldContent>
            {field.type === 'file' ? (
              <>
                <input
                  type={field.type}
                  name={field.name}
                  id={field.name}
                  placeholder={field.placeholder}
                  onChange={handleFileUpload}
                  required={field.required}
                  accept=".pdf"
                />
                <LabelButton htmlFor="resume">{fileName || field.placeholder}</LabelButton>
              </>
            ) : field.type === 'textarea' ? (
              <label htmlFor={field.name}>
                <textarea
                  name="message"
                  placeholder={field.placeholder}
                  rows="7"
                  onChange={handleChange}
                />
              </label>
            ) : field.type === 'checklist' ? (
              <Checklist>
                {field.items &&
                  field.items.map(item => (
                    <li key={item}>
                      <input type="checkbox" id={item} value={item} />
                      <label htmlFor={item}>
                        <span>{item}</span>
                      </label>
                    </li>
                  ))}
              </Checklist>
            ) : (
              <label htmlFor={field.name}>
                <input
                  type={field.type}
                  name={field.name}
                  placeholder={field.placeholder}
                  onChange={handleChange}
                  pattern={field.pattern ? field.pattern : '(.*?)'}
                  minLength={field.type === 'tel' ? 10 : 0}
                  required={field.required}
                />
              </label>
            )}
          </FieldContent>

          {!isValid && (
            <FieldError>
              {field.error ? field.error : `Please fill in this field before continuing`}
            </FieldError>
          )}
        </FieldWrapper>
      ))}
  </>
);

Field.propTypes = {
  content: PropTypes.array.isRequired,
  activeField: PropTypes.number.isRequired,
  animDirection: PropTypes.string.isRequired,
  preambleActive: PropTypes.bool.isRequired,
  readyForReview: PropTypes.bool.isRequired,
  fileName: PropTypes.string.isRequired,
  isValid: PropTypes.bool.isRequired,
  handleFileUpload: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default Field;
