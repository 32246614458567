import { css } from 'styled-components';
import theme from './theme';
import media from './media';
const { colors, fonts, fontSizes } = theme;

const mixins = {
  flexCenter: css`
    display: flex;
    justify-content: center;
    align-items: center;
  `,

  flexBetween: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,

  outline: css`
    outline: 1px solid red;
  `,

  link: css`
    position: relative;
    display: inline-block;
    text-decoration: none;
    text-decoration-skip-ink: auto;
    transition: ${theme.transition};
    cursor: pointer;
    color: ${theme.colors.altBlue};

    &:hover,
    &:active,
    &:focus {
      color: ${theme.colors.white};
    }
  `,

  button: css`
    display: inline-block;
    outline: 0;
    transition: ${theme.transition};
    font-family: ${fonts.base};
    font-size: ${fontSizes.lg};
    font-weight: 700;
    padding: 25px 30px;
    background: transparent;
    border: 1px solid ${colors.mediumGrey};
    color: ${colors.mediumGrey};
    cursor: pointer;

    ${media.phone`
      padding: 15px 20px;
      font-size: ${fontSizes.md};
    `};

    &:hover,
    &:focus {
      border-color: ${colors.altBlue};
      background-color: ${colors.altBlue};
      color: ${colors.white};
    }

    &:disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  `,
};

export default mixins;
